import React, { useState, useEffect } from "react";
import Vpsheader from "./Vpsheader";
import VpsFooter from "./VpsFooter";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Carousel from "react-bootstrap/Carousel";
import { FaAmazon } from "react-icons/fa";
import { SiFlipkart } from "react-icons/si";
import { Link } from "react-router-dom";
import $ from "jquery";
import { Helmet } from "react-helmet";
import '../Style/range.css'

function OurRanges() {
  const [rangeData, setRangeData] = useState([]);
  const headers = {};
  const search = "";
  var url = process.env.REACT_APP_VercelUrl + "/ranges";
  const getRangesData = async () => {
    // const res = await fetch(process.env.REACT_APP_VercelUrl + "/products", {
    const res = await fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
        }
        //$(".moldLoader").removeClass("d-flex").addClass("d-none");
        //$(".moldContent").addClass("d-flex").removeClass("d-none");
        return response.json();
      })
      .then((data) => {
        setRangeData(data.myData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getRangesData();
  }, [search]);
  return (
    <>
      <Vpsheader />
      <Helmet>
          <meta charset="utf-8" />
          <title>Shop Our Collection Everywhere!</title>
          <meta
            name="description"
            content="Browse our curated selection conveniently available on Amazon and Flipkart. Your favorite products just a click away!"
          />
          <meta
            name="keywords"
            content="Water gun, Pichkari, Gun, Toy, Water toy"
          />
          <meta name="author" content="V.P.S Engineering Corporation" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="vpsecorp" />
          <meta
            name="twitter:title"
            content="Discover Our Mold Masterpieces"
          />
          <meta
            name="twitter:description"
            content="Explore our diverse range of meticulously crafted molds, each a testament to our dedication to perfection."
          />
          <meta name="twitter:creator" content="vpsecorp" />
          <meta
            name="twitter:image"
            content="https://www.vpsecorp.com/vpslogo.png"
          />
          <link rel="canonical" href="https://www.vpsecorp.com/" />
          <meta
            property="og:title"
            content="Discover Our Mold Masterpieces"
          />
          <meta property="og:url" content="https://www.vpsecorp.com/" />
          <meta
            property="og:image"
            content="https://www.vpsecorp.com/vpslogo.png"
          />
          <meta
            property="og:description"
            content="Explore our diverse range of meticulously crafted molds, each a testament to our dedication to perfection."
          />
          <meta property="og:site_name" content="vpsecorp" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5"
          />
          <meta http-equiv="Cache-Control" content="no-cache" />
        </Helmet>
      <div className="container d-flex justify-content-center my-5 moldLoader">
              <div className="spinner-grow spinner-grow-sm mx-1" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div className="spinner-grow spinner-grow-sm mx-1" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div className="spinner-grow spinner-grow-sm mx-1" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div className="spinner-grow spinner-grow-sm mx-1" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div className="spinner-grow spinner-grow-sm mx-1" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div className="spinner-grow spinner-grow-sm mx-1" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
            <div className="container">
      <Row xs={1} md={3} className="g-4 m-1 p-1">
        {rangeData.map((item, idx) => (
          <Col key={idx}>
            <Card className="rangeCard">
              <Carousel data-bs-theme="dark" controls={false}>
                <Carousel.Item>
                  <Card.Img
                    variant="top"
                    className="d-block w-100 rangeImg"
                    src={item.Image1}
                    alt={item.Title}
                    title={idx}
                  />
                  {/* <Carousel.Caption>
                    <h5>First slide label</h5>
                    <p>
                      Nulla vitae elit libero, a pharetra augue mollis interdum.
                    </p>
                  </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                  <Card.Img
                    variant="top"
                    className="d-block w-100 rangeImg"
                    src={item.Image2}
                    alt={item.Title}
                  />
                </Carousel.Item>
                {item.Image3 !== null && item.Image3 !== "" && (
                  <Carousel.Item>
                    <Card.Img
                      variant="top"
                      className="d-block w-100 rangeImg"
                      src={item.Image3}
                      alt={item.Title}
                    />
                  </Carousel.Item>
                )}
                {rangeData.length-1 === idx  && ($(".moldLoader").removeClass("d-flex").addClass("d-none"),
                $(".moldContent").addClass("d-flex").removeClass("d-none"))}
                {item.Image4 !== null && item.Image4 !== "" && (
                  <Carousel.Item>
                    <Card.Img
                      variant="top"
                      className="d-block w-100 rangeImg"
                      src={item.Image4}
                      alt={item.Title}
                    />
                  </Carousel.Item>
                )}
                {item.Image5 !== null && item.Image5 !== "" && (
                  <Carousel.Item>
                    <Card.Img
                      variant="top"
                      className="d-block w-100 rangeImg"
                      src={item.Image5}
                      alt={item.Title}
                    />
                  </Carousel.Item>
                )}
              </Carousel>
              <Card.Body className="d-flex flex-column justify-content-between">
                <Card.Title className="rangeTitle">{item.Title}</Card.Title>
                <div className="text-wrap range-card-text">
                <Card.Text>
                {item.Description}
                </Card.Text>
                </div>
                <div className="d-flex justify-content-around align-items-center my-2">
                {item.AmazonUrl !== null && item.AmazonUrl !== "" && <Link className="btn text-light productBtn p-2" to={item.AmazonUrl} target="_blank">
                   Buy on <FaAmazon size={20} fill="white" />
                </Link>}
                {item.FlipkartUrl !== null && item.FlipkartUrl !== "" && <Link className="btn text-light productBtn p-2" to={item.FlipkartUrl} target="_blank">
                   Buy on <SiFlipkart size={20} fill="white" />
                </Link>}
                
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      </div>
      <div className="container">
        <hr />
      </div>
      <VpsFooter />
    </>
  );
}
export default OurRanges;
