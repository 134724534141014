import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ContactUs from "./Components/ContactUs";
import HomePage from "./Components/HomePage";
import GoToTop from "./Components/GoToTop";
import VpsSingleProduct from "./Components/VpsSingleProduct";
import MemberLogin from "./Components/MemberLogin";
import VpsMemberPortal from "./Components/VpsMemberPortal";
import VpsProductsBootStrap from "./Components/VpsProductsBootStrap";
import VpsFacilities from "./Components/VpsFacilities";
import AddMachines from "./Components/AddMachines";
import VpsBootstrapAboutus from "./Components/VpsBootstrapAboutus";
import VpsResultPage from "./Components/VpsResultPage";
import Comingsoon from "./Components/Comingsoon";
import Ourteampage from "./Components/Ourteampage";
import { Helmet } from "react-helmet";
import AddRanges from "./Components/AddRanges";
import OurRanges from "./Components/OurRanges";
import Error404 from "./Components/Error404";
function App() {
  return (
    <>
      <Router>
        <Helmet>
          <meta charset="utf-8" />
          <title>Masterful Mold Makers: Crafting Dreams</title>
          <meta
            name="description"
            content="Crafting dreams from mold to reality! Discover bespoke creations with our expert mold makers today."
          />
          <meta
            name="keywords"
            content="Mold, Mould, Injection molding, Injection moulding, Automobile mold, Toy mold"
          />
          <meta name="author" content="V.P.S Engineering Corporation" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="vpsecorp" />
          <meta
            name="twitter:title"
            content="Masterful Mold Makers: Crafting Dreams"
          />
          <meta
            name="twitter:description"
            content="Crafting dreams from mold to reality! Discover bespoke creations with our expert mold makers today."
          />
          <meta name="twitter:creator" content="vpsecorp" />
          <meta
            name="twitter:image"
            content="https://www.vpsecorp.com/vpslogo.png"
          />
          <link rel="canonical" href="https://www.vpsecorp.com/" />
          <meta
            property="og:title"
            content="Masterful Mold Makers: Crafting Dreams"
          />
          <meta property="og:url" content="https://www.vpsecorp.com/" />
          <meta
            property="og:image"
            content="https://www.vpsecorp.com/vpslogo.png"
          />
          <meta
            property="og:description"
            content="Crafting dreams from mold to reality! Discover bespoke creations with our expert mold makers today."
          />
          <meta property="og:site_name" content="vpsecorp" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5"
          />
          <meta http-equiv="Cache-Control" content="no-cache" />
        </Helmet>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/singleProduct" element={<VpsSingleProduct />} />
          <Route exact path="/contact" element={<ContactUs />} />
          <Route exact path="/memberlogin" element={<MemberLogin />} />
          <Route
            exact
            path="/memberlogin/MemberPortal"
            element={<VpsMemberPortal />}
          />
          {/* <Route exact path="/products" element={<Comingsoon />} /> */}
          <Route exact path="/products" element={<VpsProductsBootStrap />} />
          {/* <Route exact path="/about" element={<VpsAboutUs/>} /> */}
          <Route exact path="/about" element={<VpsBootstrapAboutus />} />
          <Route exact path="/facilities" element={<VpsFacilities />} />
          {/* <Route exact path="/manufacture" element={<VpsManufacturing/>} /> */}
          <Route exact path="/manufacture" element={<Comingsoon />} />
          <Route
            exact
            path="/memberlogin/Addmachines"
            element={<AddMachines />}
          />
          <Route
            exact
            path="/memberlogin/Addranges"
            element={<AddRanges />}
          />
          <Route exact path="/searchResult" element={<VpsResultPage />} />
          <Route exact path="/ourteam" element={<Ourteampage />} />
          <Route exact path="/ourranges" element={<OurRanges />} />
          <Route exact path="/error-404" element={<Error404 />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
        <GoToTop />
      </Router>
    </>
  );
}

export default App;
