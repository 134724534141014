import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
export default function VpsFooter() {
  return (
    <footer className="footer py-2">
      <Container>
        <Row>
          <Col md={4}>
            <img
              src={process.env.PUBLIC_URL + "/vpslogo.png"}
              height="65"
              className="my-3"
              alt="logo"
            />
            <h5>
              <strong>Company</strong>
            </h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/contact">FAQ</Link>
              </li>
            </ul>
          </Col>
          <Col md={4} className="my-3">
            <h5>
              <strong>Facilities</strong>
            </h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/facilities">Laser Engraving</Link>
              </li>
              <li>
                <Link to="/facilities">CNC EDM</Link>
              </li>
              <li>
                <Link to="/facilities">Surface Grinding</Link>
              </li>
              <li>
                <Link to="/facilities">Injection Moulding</Link>
              </li>
              <li>
                <Link to="/facilities">Blow Moulding</Link>
              </li>
            </ul>
          </Col>
          <Col md={4} className="my-3">
            <h5 className="">
              <strong>Stay Connected</strong>
            </h5>
            <ul className="list-unstyled d-flex social-icons navbar-nav flex-row justify-content-between w-50">
              <li className="nav-item">
                <Link className="nav-link" to="https://www.facebook.com/p/VPS-Engineering-Corporation-61555293345897/" target="_blank">
                  <FontAwesomeIcon icon={faFacebook} size="xl" color="white" />
                </Link>
              </li>
              <li className="">
                <Link className="nav-link" to="https://twitter.com/vpsecorp" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} size="xl" />
                </Link>
              </li>
              <li className="">
                <Link className="nav-link"
                  to="https://www.instagram.com/vpsecorp/?igshid=YmMyMTA2M2Y%3D"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faInstagram} size="xl" />
                </Link>
              </li>
              <li className="">
                <Link className="nav-link"
                  to="https://www.linkedin.com/company/v-p-s-engineering-corporation/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faLinkedin} size="xl" />
                </Link>
              </li>
            </ul>
            {/* <p className="about-content">Subscribe to our newsletter to get the latest news and updates</p>
            <form>
              <div className="form-group mb-2">
                <input type="email" className="form-control" placeholder="Enter your email" />
              </div>
              <button type="submit" className="btn btn-primary">Subscribe</button>
            </form> */}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className="d-flex align-items-center justify-content-center">
            <p className="about-content">© 2024 All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
