import React, { useState, useRef } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import Vpsheader from "./Vpsheader";
import VpsFooter from "./VpsFooter";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function Ourteampage() {
  const [activeSlide, setActiveSlide] = useState(0);
  const swiperRef = useRef();

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper.activeIndex);
  };

  const handleNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
      setActiveSlide(swiperRef.current.realIndex);
    }
  };

  const handlePrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
      setActiveSlide(swiperRef.current.realIndex);
    }
  };

  return (
    <>
      <Vpsheader />
      <div className="container-fluid bg-dark">
        <div className="row">
          <div className="col-md-6 col-lg-7 p-0 position-relative">
            {/* Content Section */}
            {activeSlide === 0 && (
              <div className="content-section text-light px-5 py-3">
                <h1 className="display-4">Content for Slide 1</h1>
                <p className="lead text-light">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet
                  non accusamus, sequi soluta culpa quae doloribus alias
                  veritatis, laboriosam deserunt impedit tenetur similique
                  perspiciatis, itaque repellendus. Error, ipsum! Quod, esse.
                  Qui fugiat, asperiores nisi laborum aut, cum ipsa vel
                  laudantium, ipsum nam facilis praesentium labore similique
                  incidunt culpa adipisci sit doloribus. Iusto veritatis
                  praesentium voluptatibus sunt, consequatur iure eum quidem.
                  Sapiente et earum placeat incidunt ipsam accusamus, corrupti
                  quo error. Eius itaque soluta vitae ex voluptatum, at
                  dignissimos rem nulla corporis tempora ipsam tenetur magnam
                  neque fugit quam sunt beatae. Ipsam vero dolorem, eos
                  excepturi mollitia cupiditate sequi animi voluptatibus sit
                  velit ad consectetur dolorum vel debitis officiis officia
                  dignissimos neque laboriosam. Quas, qui vitae labore velit
                  sint quia id? In animi dolorem impedit dolores optio rem
                  minima sint quia cumque explicabo beatae, corrupti
                  consequuntur voluptate enim? Labore soluta perferendis
                  voluptates minus, explicabo aut nemo asperiores culpa illum?
                  Aperiam, nostrum?
                </p>
                {/* Add more content as needed */}
              </div>
            )}
            {activeSlide === 1 && (
              <div className="content-section text-light px-5 py-3">
                <h1 className="display-4">Content for Slide 2</h1>
                <p className="lead text-light">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet
                  non accusamus, sequi soluta culpa quae doloribus alias
                  veritatis, laboriosam deserunt impedit tenetur similique
                  perspiciatis, itaque repellendus. Error, ipsum! Quod, esse.
                  Qui fugiat, asperiores nisi laborum aut, cum ipsa vel
                  laudantium, ipsum nam facilis praesentium labore similique
                  incidunt culpa adipisci sit doloribus. Iusto veritatis
                  praesentium voluptatibus sunt, consequatur iure eum quidem.
                  Sapiente et earum placeat incidunt ipsam accusamus, corrupti
                  quo error. Eius itaque soluta vitae ex voluptatum, at
                  dignissimos rem nulla corporis tempora ipsam tenetur magnam
                  neque fugit quam sunt beatae. Ipsam vero dolorem, eos
                  excepturi mollitia cupiditate sequi animi voluptatibus sit
                  velit ad consectetur dolorum vel debitis officiis officia
                  dignissimos neque laboriosam. Quas, qui vitae labore velit
                  sint quia id? In animi dolorem impedit dolores optio rem
                  minima sint quia cumque explicabo beatae, corrupti
                  consequuntur voluptate enim? Labore soluta perferendis
                  voluptates minus, explicabo aut nemo asperiores culpa illum?
                  Aperiam, nostrum?
                </p>
                {/* Add more content as needed */}
              </div>
            )}
            {activeSlide === 2 && (
              <div className="content-section text-light px-5 py-3">
                <h1 className="display-4">Content for Slide 3</h1>
                <p className="lead text-light">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet
                  non accusamus, sequi soluta culpa quae doloribus alias
                  veritatis, laboriosam deserunt impedit tenetur similique
                  perspiciatis, itaque repellendus. Error, ipsum! Quod, esse.
                  Qui fugiat, asperiores nisi laborum aut, cum ipsa vel
                  laudantium, ipsum nam facilis praesentium labore similique
                  incidunt culpa adipisci sit doloribus. Iusto veritatis
                  praesentium voluptatibus sunt, consequatur iure eum quidem.
                  Sapiente et earum placeat incidunt ipsam accusamus, corrupti
                  quo error. Eius itaque soluta vitae ex voluptatum, at
                  dignissimos rem nulla corporis tempora ipsam tenetur magnam
                  neque fugit quam sunt beatae. Ipsam vero dolorem, eos
                  excepturi mollitia cupiditate sequi animi voluptatibus sit
                  velit ad consectetur dolorum vel debitis officiis officia
                  dignissimos neque laboriosam. Quas, qui vitae labore velit
                  sint quia id? In animi dolorem impedit dolores optio rem
                  minima sint quia cumque explicabo beatae, corrupti
                  consequuntur voluptate enim? Labore soluta perferendis
                  voluptates minus, explicabo aut nemo asperiores culpa illum?
                  Aperiam, nostrum?
                </p>
                {/* Add more content as needed */}
              </div>
            )}
            <div className="d-flex  flex-column align-items-center teamPageIcon teamPageIconDesk text-light">
              <div className="d-flex align-items-center">
                <BsFillArrowLeftCircleFill
                  color="white"
                  onClick={handlePrevSlide}
                  size="30px"
                />
                <div
                  className="swiper-pagination position-static w-auto mx-3 swiper-pagination-fraction swiper-pagination-horizontal"
                  style={{ minWidth: "30px" }}
                >
                  <span className="swiper-pagination-current text-light">
                    {activeSlide + 1}
                  </span>{" "}
                  /{" "}
                  <span className="swiper-pagination-total text-light">3</span>
                </div>
                <BsFillArrowRightCircleFill
                  color="white"
                  onClick={handleNextSlide}
                  size="30px"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-5 p-0 teamSwiper">
            {/* Swiper Slider */}
            <Swiper
              ref={swiperRef}
              spaceBetween={30}
              slidesPerView={1}
              loop={true}
              onSlideChange={(swiper) => handleSlideChange(swiper)}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              allowTouchMove={false}
            >
              <SwiperSlide>
                <img
                  src={process.env.PUBLIC_URL + "/MILACRONresize.webp"}
                  alt="Slide 1"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={process.env.PUBLIC_URL + "/HaasVM3resize.webp"}
                  alt="Slide 2"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={process.env.PUBLIC_URL + "/20230422_114556resize.webp"}
                  alt="Slide 3"
                />
              </SwiperSlide>
            </Swiper>
            <div className="d-flex  flex-column align-items-center teamPageIcon py-3 teamPageIconmob text-light">
              <div className="d-flex align-items-center">
                <BsFillArrowLeftCircleFill
                  color="white"
                  onClick={handlePrevSlide}
                  size="30px"
                />
                <div
                  className="swiper-pagination position-static w-auto mx-3 swiper-pagination-fraction swiper-pagination-horizontal"
                  style={{ minWidth: "30px" }}
                >
                  <span className="swiper-pagination-current text-light">
                    {activeSlide + 1}
                  </span>{" "}
                  /{" "}
                  <span className="swiper-pagination-total text-light">3</span>
                </div>
                <BsFillArrowRightCircleFill
                  color="white"
                  onClick={handleNextSlide}
                  size="30px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <VpsFooter />
    </>
  );
}
