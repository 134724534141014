import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import VpsMemberPortalHeader from "./VpsMemberPortalHeader";
import "../Style/memberLogin.css";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";

function AddRanges() {
  const location = useLocation();
  var IsLogin = "false";
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [imageBlob, setImageBob] = useState([]);
  const [records, setRecords] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [paginationLinksHTML, setpaginationLinksHTML] = useState([]);
  // var url = process.env.REACT_APP_LocalUrl + "/ranges?page=1";
  var url = process.env.REACT_APP_VercelUrl + "/ranges?page=1";
  var headers = {};
  useEffect(() => {
    // url =
    //   searchVal !== ""
    //     ? process.env.REACT_APP_LocalUrl + "/ranges" + `?title=` + searchVal
    //     : process.env.REACT_APP_LocalUrl + "/ranges?page=1";
    url =
      searchVal !== ""
        ? process.env.REACT_APP_VercelUrl + "/ranges" + `?Title=` + searchVal
        : process.env.REACT_APP_VercelUrl + "/ranges?page=1";
    getData();
    IsLogin =
      sessionStorage.getItem("IsLogin") !== null
        ? sessionStorage.getItem("IsLogin").toString()
        : false;
  }, [searchVal, totalRecord]);
  const columns = [
    {
      name: "Title",
      selector: (row) => row.Title,
      sortable: true,
    },
    {
      name: "Image",
      selector: (row) => <img width={100} height={100} src={row.Image1} />,
      sortable: true,
    },
    {
      name: "Image2",
      selector: (row) => <img width={100} height={100} src={row.Image2} />,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.Description,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) =>
        row.Active !== undefined && row.Active !== null
          ? row.Active.toString()
          : "",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            className="btn btn-primary me-2"
            id={row._id}
            title={row.Title}
            is_active={
              row.Active !== undefined && row.Active !== null
                ? row.Active.toString()
                : ""
            }
            long_des={row.Description}
            image={row.Image1}
            image2={row.Image2}
            image3={row.Image3}
            image4={row.Image4}
            image5={row.Image5}
            amazonurl={row.AmazonUrl}
            flipkarturl={row.FlipkartUrl}
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={editRange}
          >
            Edit
          </button>
          <button
            className="btn btn-primary me-2"
            id={row._id}
            onClick={
              row.Active !== undefined && row.Active !== null
                ? row.Active.toString() === "true"
                  ? deactivateRange
                  : activateRange
                : ""
            }
          >
            {row.Active !== undefined && row.Active !== null
              ? row.Active.toString() === "true"
                ? "Deactivate"
                : "Activate"
              : ""}
          </button>
        </>
      ),
      sortable: true,
    },
  ];
  const addRangeModal = () => {
    $("#exampleModal").find("input, textarea").val("");
    $("#exampleModal").find("#isShow").prop("checked", false);
    $("#exampleModal").find("#isActive").prop("checked", true);
    $("#exampleModal").find(".shortDesc").addClass("d-none");
    $("#exampleModal").find(".saveBtn").text("Save").removeAttr("range_id");
    setImage("");
  };
  const editRange = (event) => {
    $("#exampleModal").find("#range-title").val(event.target.title);
    $("#exampleModal")
      .find("#range-desc")
      .val($(event.target).attr("long_des"));
    $(event.target).attr("is_active") === "true"
      ? $("#exampleModal").find("#isActive").prop("checked", true)
      : $("#exampleModal").find("#isActive").prop("checked", false);
    $("#exampleModal")
      .find("#range-image1")
      .val($(event.target).attr("image"));
    $("#exampleModal")
      .find("#range-image2")
      .val($(event.target).attr("image2"));
    $("#exampleModal")
      .find("#range-image3")
      .val($(event.target).attr("image3"));
    $("#exampleModal")
      .find("#range-image4")
      .val($(event.target).attr("image4"));
    $("#exampleModal")
      .find("#range-image5")
      .val($(event.target).attr("image5"));
    $("#exampleModal")
      .find("#amazon-url")
      .val($(event.target).attr("amazonurl"));
    $("#exampleModal")
      .find("#flipkart-url")
      .val($(event.target).attr("flipkarturl"));
    $("#exampleModal")
      .find(".saveBtn")
      .text("Update")
      .attr("range_id", event.target.id);
  };
  const deactivateRange = async (event) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to deactivate the range!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, deactivate it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await fetch(
          process.env.REACT_APP_VercelUrl + "/ranges/deactivateranges",
          {
            method: "POST",
            body: JSON.stringify({
              id: event.target.id,
            }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        ).then((res) => {
          if (res.status > 199 && res.status < 300) {
            Swal.fire(
              "Deactivated!",
              "Your file has been deactivated.",
              "success"
            );
            getData();
          }
        });
      }
    });
  };
  const activateRange = async (event) => {
    await fetch(process.env.REACT_APP_VercelUrl + "/ranges/activateranges", {
      method: "POST",
      body: JSON.stringify({
        id: event.target.id,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status > 199 && res.status < 300) {
        Swal.fire("Activated!", "Your file has been activated.", "success");
        getData();
      }
    });
  };
  const toggleSwitch = () => {
    // if ($('#flexSwitchCheckChecked').is(':checked')){
    //   $('#flexSwitchCheckChecked').prop('checked',false)
    // }
    // else{
    //   $('#flexSwitchCheckChecked').prop('checked',true)
    // }
  };
  const getData = async () => {
    // url = process.env.REACT_APP_VercelUrl + "/ranges/saveranges";
    //url = process.env.REACT_APP_LocalUrl+ "/ranges?page="+currentPage;
    const res = await fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
        }
        return response.json();
      })
      .then((data) => {
        setRecords(data.myData);
        setTotalRecord(data.nbHits);
        renderPaginationLinks();
      })
      .catch(function (error) {});
  };
  const renderPaginationLinks = () => {
    if (totalRecord > 0) {
      const paginationLinks = [];
      for (let index = 0; index < Math.ceil(totalRecord / 10); index++) {
        if (index === 0) {
          paginationLinks.push(
            <li className="page-item" key={index}>
              <button
                className="page-link numBtn firstButton active"
                pagenum={index + 1}
                onClick={getPageData}
              >
                {index + 1}
              </button>
            </li>
          );
        } else if (index === Math.ceil(totalRecord / 10) - 1) {
          paginationLinks.push(
            <li className="page-item" key={index}>
              <button
                className="page-link numBtn lastButton"
                pagenum={index + 1}
                onClick={getPageData}
              >
                {index + 1}
              </button>
            </li>
          );
        } else {
          paginationLinks.push(
            <li className="page-item numItems" key={index}>
              <button
                className="page-link numBtn"
                pagenum={index + 1}
                onClick={getPageData}
              >
                {index + 1}
              </button>
            </li>
          );
        }
      }
      setpaginationLinksHTML(paginationLinks);
    }
  };
  const getPageData = (event) => {
    event.stopPropagation();
    $(".numBtn").removeClass("active");
    $(event.target).addClass("active");
    setCurrentPage(parseInt($(event.target).attr("pagenum")));
    url =
      process.env.REACT_APP_VercelUrl +
      "/ranges?page=" +
      $(event.target).attr("pagenum");
    getData();
    if ($(event.target).hasClass("firstButton")) {
      $(".previousBtn").addClass("disabled");
      $(".nextBtn").removeClass("disabled");
    } else if ($(event.target).hasClass("lastButton")) {
      $(".previousBtn").removeClass("disabled");
      $(".nextBtn").addClass("disabled");
    } else {
      $(".previousBtn").removeClass("disabled");
      $(".nextBtn").removeClass("disabled");
    }
  };
  const goToprevious = () => {
    var currentPage = parseInt($(".page-item .numBtn.active").attr("pagenum"));
    $(".page-item .numBtn").removeClass("active");
    $(".page-item .numBtn[pagenum=" + (currentPage - 1) + "]").addClass(
      "active"
    );
    url = process.env.REACT_APP_VercelUrl + "/ranges?page=" + (currentPage - 1);
    getData();
    if (
      $(".page-item .numBtn[pagenum=" + (currentPage - 1) + "]").hasClass(
        "firstButton"
      )
    ) {
      $(".previousBtn").addClass("disabled");
    }
    $(".nextBtn").removeClass("disabled");
  };
  const goToNext = () => {
    var currentPage = parseInt($(".page-item .numBtn.active").attr("pagenum"));
    $(".page-item .numBtn").removeClass("active");
    $(".page-item .numBtn[pagenum=" + (currentPage + 1) + "]").addClass(
      "active"
    );
    url = process.env.REACT_APP_VercelUrl + "/ranges?page=" + (currentPage + 1);
    getData();
    if (
      $(".page-item .numBtn[pagenum=" + (currentPage + 1) + "]").hasClass(
        "lastButton"
      )
    ) {
      $(".nextBtn").addClass("disabled");
    }
    $(".previousBtn").removeClass("disabled");
  };
  // const { Buffer } = require('buffer');
  // const Binary  = require('binary');
  function validateField(id) {
    if ($(id).val() === "") {
      $(id).addClass("is-invalid");
      return false;
    } else {
      $(id).removeClass("is-invalid");
      return true;
    }
  }
  async function handleSubmit(event) {
    event.preventDefault();
    let valid = true;
    valid = valid & validateField("#range-title");
    valid = valid & validateField("#range-image1");
    valid = valid & validateField("#range-desc");
    let range = {};
    let url = "";
    if (valid) {
      if ($(event.target).attr("range_id") !== undefined) {
        url = process.env.REACT_APP_VercelUrl + "/ranges/updateranges";
        // url = process.env.REACT_APP_LocalUrl+ "/ranges/updateranges";
        range = {
          id: $(event.target).attr("range_id"),
          Title: $("#range-title").val(),
          Image1: $("#range-image1").val(),
          Image2: $("#range-image2").val(),
          Image3: $("#range-image3").val(),
          Image4: $("#range-image4").val(),
          Image5: $("#range-image5").val(),
          Description: $("#range-desc").val(),
          AmazonUrl: $("#amazon-url").val(),
          FlipkartUrl: $("#flipkart-url").val(),
          Active: $("#isActive").is(":checked"),
        };
      } else {
        url = process.env.REACT_APP_VercelUrl + "/ranges/saveranges";
        // url = process.env.REACT_APP_LocalUrl + "/ranges/saveranges";
        range = {
          Title: $("#range-title").val(),
          Image1: $("#range-image1").val(),
          Image2: $("#range-image2").val(),
          Image3: $("#range-image3").val(),
          Image4: $("#range-image4").val(),
          Image5: $("#range-image5").val(),
          Description: $("#range-desc").val(),
          AmazonUrl: $("#amazon-url").val(),
          FlipkartUrl: $("#flipkart-url").val(),
          Active: $("#isActive").is(":checked"),
        };
      }

      await fetch(url, {
        method: "POST",
        body: JSON.stringify(range),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res.status > 199 && res.status < 300) {
          Swal.fire({
            icon: "success",
            title: "Range Saved Successfully!",
            showConfirmButton: true,
            timer: 2500,
          }).then(() => {
            $("#exampleModal").modal("hide");
          });
          getData();
        }
      });
    }
    // ...
    setSelectedFile(null);
    setPreviewUrl("");
    setImage("");
  }
  if (
    sessionStorage.getItem("IsLogin") !== null
      ? sessionStorage.getItem("IsLogin").toString() === "true"
      : false
  ) {
    return (
      <>
        <VpsMemberPortalHeader />
        <div className="d-grid gap-2 m-3 d-md-flex justify-content-md-end">
          <button
            type="button"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={addRangeModal}
          >
            Add Range
          </button>
        </div>
        <div id="dataTable">
          <DataTable
            title="Range List"
            columns={columns}
            data={records}
            theme="dark"
            pagination={false}
            fixedHeader
            fixedHeaderScrollHeight="450px"
            // selectableRows
            // selectableRowsHighlight
            highlightOnHover
            // actions={
            //   <button className="btn btn-primary">Export</button>
            // }
            subHeaderComponent={
              <input
                type="text"
                placeholder="Search here"
                value={searchVal}
                onChange={(e) => setSearchVal(e.target.value)}
                className="w-25 form-control"
              />
            }
            subHeader={
              <input
                type="text"
                placeholder="Search here"
                value={searchVal}
                onChange={(e) => setSearchVal(e.target.value)}
                className="w-15 form-control"
              />
            }
          />
          <nav
            aria-label="Page navigation example"
            className="justify-content-center"
          >
            <ul className="pagination justify-content-center">
              <li className="page-item ">
                <button
                  className="page-link previousBtn disabled"
                  onClick={goToprevious}
                  tabIndex="-1"
                >
                  Previous
                </button>
              </li>
              {paginationLinksHTML}
              <li className="page-item">
                <button className="page-link nextBtn" onClick={goToNext}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Add Range
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="range-title" className="col-form-label">
                      Range Title:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="range-title"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="range-image1" className="col-form-label">
                      Range Image Url 1:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="range-image1"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="range-image2" className="col-form-label">
                      Range Image Url 2:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="range-image2"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="range-image3" className="col-form-label">
                      Range Image Url 3:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="range-image3"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="range-image4" className="col-form-label">
                      Range Image Url 4:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="range-image4"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="range-image5" className="col-form-label">
                      Range Image Url 5:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="range-image5"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="amazon-url" className="col-form-label">
                      Amazon Url:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="amazon-url"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="flipkart-url" className="col-form-label">
                      Flipkart Url:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="flipkart-url"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="range-desc" className="col-form-label">
                      Range Description:
                    </label>
                    <textarea
                      className="form-control"
                      id="range-desc"
                    ></textarea>
                  </div>
                  <div className="mb-3">
                    <input
                      type="checkbox"
                      id="isActive"
                      onChange={toggleSwitch}
                      className="me-2"
                      name="fav_language"
                      value="Active"
                    />
                    <label htmlFor="isActive">Active</label>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btn btn-primary saveBtn"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div class="error">
        <div class="container-floud">
          <div class="col-xs-12 ground-color text-center">
            <div class="container-error-404">
              <div class="clip">
                <div class="shadow">
                  <span class="digit thirdDigit"></span>
                </div>
              </div>
              <div class="clip">
                <div class="shadow">
                  <span class="digit secondDigit"></span>
                </div>
              </div>
              <div class="clip">
                <div class="shadow">
                  <span class="digit firstDigit"></span>
                </div>
              </div>
              <div class="msg">
                OH!<span class="triangle"></span>
              </div>
            </div>
            <h2 class="h1">Sorry! Page not found</h2>
          </div>
        </div>
      </div>
    );
  }
}
export default AddRanges;
