import React from "react";
import Vpsheader from "./Vpsheader";
// import { BiAtom } from "react-icons/bi";
import VpsFooter from "./VpsFooter";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function VpsFacilities() {
  const navigate = useNavigate();
  const navigateToContact = () =>{
    navigate("/contact");
  }
  return (
    <>
    <Helmet>
          <meta charset="utf-8" />
          <title>Exploring Our Cutting-Edge Facilities</title>
          <meta
            name="description"
            content="Step into our state-of-the-art facilities where innovation meets precision in the art of mold making."
          />
          <meta
            name="keywords"
            content="Mold, Mould, Injection molding, Injection moulding, Automobile mold, Toy mold"
          />
          <meta name="author" content="V.P.S Engineering Corporation" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="vpsecorp" />
          <meta
            name="twitter:title"
            content="Exploring Our Cutting-Edge Facilities"
          />
          <meta
            name="twitter:description"
            content="Step into our state-of-the-art facilities where innovation meets precision in the art of mold making."
          />
          <meta name="twitter:creator" content="vpsecorp" />
          <meta
            name="twitter:image"
            content="https://www.vpsecorp.com/vpslogo.png"
          />
          <link rel="canonical" href="https://www.vpsecorp.com/" />
          <meta
            property="og:title"
            content="Exploring Our Cutting-Edge Facilities"
          />
          <meta property="og:url" content="https://www.vpsecorp.com/" />
          <meta
            property="og:image"
            content="https://www.vpsecorp.com/vpslogo.png"
          />
          <meta
            property="og:description"
            content="Step into our state-of-the-art facilities where innovation meets precision in the art of mold making."
          />
          <meta property="og:site_name" content="vpsecorp" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5"
          />
          <meta http-equiv="Cache-Control" content="no-cache" />
        </Helmet>
      <Vpsheader />
      <div className="container px-4 py-5 hero" id="hanging-icons">
      <h1 className="d-flex align-items-center justify-content-center">Facilities</h1>
        <div className="row g-4 py-5 row-cols-1 row-cols-lg-2">
        <div className="col d-flex mb-4 align-items-start">
            <div className="icon-square text-body-emphasis rounded bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
              {/* <BiAtom /> */}
              <img src={process.env.PUBLIC_URL + "/ux.webp"} alt="Injection Moulding" width={96} height={96} />
            </div>
            <div>
              <h3 className="fs-2">3D CAD Design</h3>
              <p>
                Technically sound and experienced engineers 
              </p>
              <button href="#" className="btn btn-primary" onClick={navigateToContact}>
                Enquire Now
              </button>
            </div>
          </div>
          <div className="col d-flex mb-4 align-items-start">
            <div className="icon-square text-body-emphasis rounded bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
              {/* <BiAtom /> */}
              <img src={process.env.PUBLIC_URL + "/drilling.webp"} alt="Injection Moulding" width={96} height={96} />
            </div>
            <div>
              <h3 className="fs-2">VMC Machining</h3>
              <p>
                With a bed size 1200*600 we can manufacture a block upto 1800*600 seamlessly.
              </p>
              <button href="#" className="btn btn-primary" onClick={navigateToContact}>
                Enquire Now
              </button>
            </div>
          </div>
          <div className="col d-flex mb-4 align-items-start">
            <div className="icon-square text-body-emphasis rounded bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
              {/* <BiAtom /> */}
              <img src={process.env.PUBLIC_URL + "/cncturning.webp"} alt="Injection Moulding" width={96} height={96} />
            </div>
            <div>
              <h3 className="fs-2">CNC Turning Center</h3>
              <p>
                Jobs are easily machined within 10 microns.
              </p>
              <button href="#" className="btn btn-primary" onClick={navigateToContact}>
                Enquire Now
              </button>
            </div>
          </div>
          <div className="col d-flex mb-4 align-items-start">
            <div className="icon-square text-body-emphasis rounded bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
              {/* <BiAtom /> */}
              <img src={process.env.PUBLIC_URL + "/helmet.webp"} alt="Injection Moulding" width={96} height={96} />
            </div>
            <div>
              <h3 className="fs-2">Tool Room Facility</h3>
              <p>
                Experienced mould fitters and calibrated measuring instruments make the part ejection process smooth.
              </p>
              <button href="#" className="btn btn-primary" onClick={navigateToContact}>
                Enquire Now
              </button>
            </div>
          </div>
          <div className="col d-flex mb-4 align-items-start">
            <div className="icon-square text-body-emphasis rounded bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
              {/* <BiAtom /> */}
              <img src={process.env.PUBLIC_URL + "/icons8-cnc-machine-96.webp"} alt="CNC EDM" />
            </div>
            <div>
              <h3 className="fs-2">CNC EDM</h3>
              <p>
                CNC EDM enables us to machine undercut, grooves & even more
                through electric discharge.
              </p>
              <button href="#" className="btn btn-primary" onClick={navigateToContact}>
                Enquire Now
              </button>
            </div>
          </div>
          <div className="col d-flex mb-4 align-items-start">
            <div className="icon-square text-body-emphasis rounded bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
              {/* <BiAtom /> */}
              <img src={process.env.PUBLIC_URL + "/machine.webp"} alt="Injection Moulding" width={96} height={96} />
            </div>
            <div>
              <h3 className="fs-2">Injection Moulding</h3>
              <p>
                250 tonn moulding facility makes it effortless to manufacture
                parts weighing upto 500 grams.
              </p>
              <button href="#" className="btn btn-primary" onClick={navigateToContact}>
                Enquire Now
              </button>
            </div>
          </div>
          <div className="col d-flex mb-4 align-items-start">
            <div className="icon-square text-body-emphasis rounded bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
              {/* <BiAtom /> */}
              <img src={process.env.PUBLIC_URL + "/icons8-grinding-machine-96.webp"} alt="Surface Grinding" />
            </div>
            <div>
              <h3 className="fs-2">Surface Grinding</h3>
              <p>
                Surface Grinding is done precisely on a workpiece upto 1000x550
                MM within 20 microns accuracy.
              </p>
              <button href="#" className="btn btn-primary" onClick={navigateToContact}>
                Enquire Now
              </button>
            </div>
          </div>
          <div className="col d-flex mb-4 align-items-start">
            <div className="icon-square text-body-emphasis rounded bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
              {/* <BiAtom /> */}
              <img src={process.env.PUBLIC_URL + "/icons8-laser-96.webp"} alt="Laser Engraving" />
            </div>
            <div>
              <h3 className="fs-2">Laser Engraving </h3>
              <p>
                We've high accuracy Laser Engraving facility in our premises for
                all types of metal. Impressions are made even on high hard
                metals like Stavax etc.
              </p>
              <button href="#" className="btn btn-primary" onClick={navigateToContact}>
                Enquire Now
              </button>
            </div>
          </div>
          <div className="col d-flex mb-4 align-items-start">
            <div className="icon-square text-body-emphasis rounded bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
              {/* <BiAtom /> */}
              <img src={process.env.PUBLIC_URL + "/icons8-molding-apparatus-100.webp"} alt="Injection Moulding" width={96} height={96} />
            </div>
            <div>
              <h3 className="fs-2">Blow Moulding</h3>
              <p>
                Components upto 15 litres are effortlessly filled.
              </p>
              <button href="#" className="btn btn-primary" onClick={navigateToContact}>
                Enquire Now
              </button>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <VpsFooter />
    </>
  );
}
