import React, { useState } from "react";
import "../Style/VpsProducts.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export default function VpsProducts() {
  const [totalRecord, setTotalRecord] = useState(0);
  const navigate = useNavigate();
  const [maxPageNum, setMaxPageNum] = useState(0);
  const [paginationLinksHTML, setpaginationLinksHTML] = useState([]);
  const [slideData, setSlideData] = useState([]);
  const headers = {};
  const search = "";
  var url = process.env.REACT_APP_VercelUrl + "/products?page=1&limit=9";
  const getProductsData = async () => {
    // const res = await fetch(process.env.REACT_APP_VercelUrl + "/products", {
    const res = await fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
        }
        $(".moldLoader").removeClass("d-flex").addClass("d-none");
        $(".moldContent").addClass("d-flex").removeClass("d-none");
        return response.json();
      })
      .then((data) => {
        $(".moldLoader").removeClass("d-flex").addClass("d-none");
        $(".moldContent").addClass("d-flex").removeClass("d-none");
        setSlideData(data.myData);
        setTotalRecord(data.nbHits);
        setMaxPageNum(Math.ceil(totalRecord / 10));
        renderPaginationLinks();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getProductsData();
  }, [totalRecord]);

  const renderPaginationLinks = () => {
    if (totalRecord > 0) {
      if (totalRecord > 10) {
        !$(".lastButton").hasClass("active") &&
          $(".nextBtn").removeClass("disabled");
      } else {
        $(".nextBtn").addClass("disabled");
      }
      const paginationLinks = [];
      for (let index = 0; index < Math.ceil(totalRecord / 10); index++) {
        if (index === 0) {
          paginationLinks.push(
            <li className="page-item" key={index}>
              <button
                className="page-link numBtn firstButton active"
                pagenum={index + 1}
                onClick={getPageData}
              >
                {index + 1}
              </button>
            </li>
          );
        } else if (index === Math.ceil(totalRecord / 10) - 1) {
          paginationLinks.push(
            <li className="page-item" key={index}>
              <button
                className="page-link numBtn lastButton"
                pagenum={index + 1}
                onClick={getPageData}
              >
                {index + 1}
              </button>
            </li>
          );
        } else {
          paginationLinks.push(
            <li className="page-item numItems" key={index}>
              <button
                className="page-link numBtn"
                pagenum={index + 1}
                onClick={getPageData}
              >
                {index + 1}
              </button>
            </li>
          );
        }
      }
      setpaginationLinksHTML(paginationLinks);
    }
  };
  const getPageData = (event) => {
    event.stopPropagation();
    $(".moldLoader").addClass("d-flex").removeClass("d-none");
    $(".moldContent").removeClass("d-flex").addClass("d-none");
    $(".numBtn").removeClass("active");
    $(event.target).addClass("active");
    url =
      process.env.REACT_APP_VercelUrl +
      "/products?page=" +
      $(event.target).attr("pagenum")+"&limit=9";
    getProductsData();
    if ($(event.target).hasClass("firstButton")) {
      $(".previousBtn").addClass("disabled");
      $(".nextBtn").removeClass("disabled");
    } else if ($(event.target).hasClass("lastButton")) {
      $(".previousBtn").removeClass("disabled");
      $(".nextBtn").addClass("disabled");
    } else {
      $(".previousBtn").removeClass("disabled");
      $(".nextBtn").removeClass("disabled");
    }
  };
  const goToprevious = () => {
    var currentPage = parseInt($(".page-item .numBtn.active").attr("pagenum"));
    $(".page-item .numBtn").removeClass("active");
    $(".page-item .numBtn[pagenum=" + (currentPage - 1) + "]").addClass(
      "active"
    );
    url =
      process.env.REACT_APP_VercelUrl + "/products?page=" + (currentPage - 1)+"&limit=9";
    getProductsData();
    if (
      $(".page-item .numBtn[pagenum=" + (currentPage - 1) + "]").hasClass(
        "firstButton"
      )
    ) {
      $(".previousBtn").addClass("disabled");
    }
    $(".nextBtn").removeClass("disabled");
  };
  const goToNext = () => {
    var currentPage = parseInt($(".page-item .numBtn.active").attr("pagenum"));
    $(".page-item .numBtn").removeClass("active");
    $(".page-item .numBtn[pagenum=" + (currentPage + 1) + "]").addClass(
      "active"
    );
    url =
      process.env.REACT_APP_VercelUrl + "/products?page=" + (currentPage + 1)+"&limit=9";
    getProductsData();
    if (
      $(".page-item .numBtn[pagenum=" + (currentPage + 1) + "]").hasClass(
        "lastButton"
      )
    ) {
      $(".nextBtn").addClass("disabled");
    }
    $(".previousBtn").removeClass("disabled");
  };
  $(".enquireNowBtn").on("click", function (event) {
    if (this !== undefined) {
      navigate("/singleProduct", {
        state: {
          name: $(this).attr("title"),
          description: $(this).attr("description"),
          image: $(this).attr("image"),
        },
      });
      $("#navbarTogglerDemo02").find(".nav-link").removeClass("active");
      $("#navbarTogglerDemo02").find(".products").addClass("active");
    }
    event.stopImmediatePropagation();
  });
  return (
    <>
      <div className="container">
        <Row xs={1} md={3} className="g-4 m-1 p-1">
          {/* <Vpsheader /> */}
          {slideData.map((item, idx) => {
            return (
              <Col key={idx}>
                <Card className="rangeCard">
                  <Card.Img
                    className="productImg"
                    variant="top"
                    src={item.image}
                    alt={item.title}
                  />
                  <Card.Body className="d-flex flex-column justify-content-between align-items-center">
                    <Card.Title className="productTitle">
                      {item.title}
                    </Card.Title>
                    <Card.Text className="range-card-text text-dark">
                      {item.LongDescription}
                    </Card.Text>
                    <Link
                      className="btn btn-outline-primary btn text-dark mt-3 enquireNowBtn"
                      image={item.image}
                      description={item.LongDescription}
                      title={item.title}
                    >
                      Enquire Now!
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>

      {/* {slideData.map((item, index) => {
        return (
          <>
            <div className="col-md-6 moldContent mb-3" key={item._id}>
              <div className="row g-0 border productBoxShadow rounded overflow-hidden flex-md-row shadow-sm h-md-250 position-relative">
                <div className="col p-4 d-flex flex-column position-static">
                  <strong className="d-inline-block mb-2 text-primary"></strong>
                  <h3 className="mb-0">{item.title}</h3>
                  <div className="mb-1 text-body-secondary"></div>
                  <p className="card-text mb-auto">{item.LongDescription}</p>
                  <a className="btn btn-primary btn-sm text-light mt-3 enquireNowBtn" image={item.image} description={item.LongDescription} title={item.title}>Enquire Now</a>
                </div>
                <div className="col-auto d-lg-block m-auto">
                  <img
                    src={item.image}
                    alt={item.title}
                    width={300}
                    height={300}
                    className="bd-placeholder-img img-fluid"
                  />
                </div>
              </div>
            </div>
          </>
        );
      })} */}
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center mt-3">
          <li className="page-item ">
            <button
              className="page-link previousBtn disabled"
              onClick={goToprevious}
              tabIndex="-1"
            >
              Previous
            </button>
          </li>
          {paginationLinksHTML}
          <li className="page-item">
            <button className="page-link nextBtn" onClick={goToNext}>
              Next
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
}
