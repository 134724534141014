import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
export default function VpsProductSlider() {
  const [slideData, setSlideData] = useState([]);
  const navigate = useNavigate();
  var slides = [];
  var slideClick = [];
  const sliderClick = (event) => {
    navigate("/singleProduct", {
      state: {
        name: slideClick[event.target.slide_id].title,
        description: slideClick[event.target.slide_id].description,
        image: slideClick[event.target.slide_id].image,
      },
    });
    $("#navbarTogglerDemo02").find(".nav-link").removeClass("active");
    $("#navbarTogglerDemo02").find(".products").addClass("active");
  };
  const headers = {};

  const search = "";
  const getProductsData = async () => {
    $(".moldLoader").addClass("d-flex").removeClass("d-none");
    $(".moldContent").removeClass("d-flex").addClass("d-none");
    // const res = await fetch(process.env.REACT_APP_LocalUrl + "/products?isShow=1", {
    const res = await fetch(
      process.env.REACT_APP_VercelUrl + "/products?isShow=1",
      {
        method: "GET",
        headers: headers,
      }
    )
      .then((response) => {
        if (!response.ok) {
        }
        $(".moldLoader").removeClass("d-flex").addClass("d-none");
        $(".moldContent").addClass("d-flex").removeClass("d-none");
        return response.json();
      })
      .then((data) => {
        $(".moldLoader").removeClass("d-flex").addClass("d-none");
        $(".moldContent").addClass("d-flex").removeClass("d-none");
        slides = [];
        $(data.myData).each(function (pr, prod) {
          if (
            (prod.IsShow || prod.IsShow === "true") &&
            (prod.Active || prod.Active === "true")
          ) {
            slides.push({
              image: prod.image,
              title: prod.title,
              description: prod.LongDescription,
              clickEvent: () => sliderClick(pr),
            });
            slideClick.push({
              image: prod.image,
              title: prod.title,
              description: prod.LongDescription,
              clickEvent: () => sliderClick(pr),
            });
            setSlideData(slides);
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getProductsData();
  }, [search]);
  const breakpoints = {
    768: {
      perPage: 1, // Show 1 slide per page on screens with width <= 768px (phones)
    },
    992: {
      perPage: 3, // Show 3 slides per page on screens with width <= 992px (tablets)
    },
    1200: {
      perPage: 4, // Show 4 slides per page on screens with width > 992px (desktops)
    },
  };
  function sliderClick1(index) {
    if (index !== undefined && slideClick.length > 0) {
      navigate("/singleProduct", {
        state: {
          name: slideClick[index].title,
          description: slideClick[index].description,
          image: slideClick[index].image,
        },
      });
      $("#navbarTogglerDemo02").find(".nav-link").removeClass("active");
      $("#navbarTogglerDemo02").find(".products").addClass("active");
    }
  }
  return (
    <>
      <h1
        className="d-flex align-items-center justify-content-center mb-4"
        id="moldRange"
      >
        Molds manufactured for parts...
      </h1>
      <div className="container d-flex justify-content-center my-5 moldLoader">
        <div className="spinner-grow spinner-grow-sm mx-1" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow spinner-grow-sm mx-1" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow spinner-grow-sm mx-1" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow spinner-grow-sm mx-1" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow spinner-grow-sm mx-1" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow spinner-grow-sm mx-1" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <Splide
              options={{
                rewind: true,
                perPage: 4,
                perMove: 1,
                gap: "1rem",
                focus: 0,
                omitEnd: true,
                wheel: false,
                pagination: false,
                breakpoints: breakpoints,
              }}
              aria-label="React Splide Example"
            >
              {slideData.map((slide, index) => (
                <SplideSlide
                  key={index}
                  className="splide__slide"
                  slide_id={index}
                  onClick={sliderClick1(index)}
                >
                  <Link
                    to="/singleProduct"
                    state={{
                      name: `${slide.title}`,
                      image: `${slide.image}`,
                      description: `${slide.description}`,
                    }}
                  >
                    <div
                      className="border border-white rounded"
                      onClick={sliderClick1(index)}
                    >
                      <img
                        src={slide.image}
                        alt={`Slide ${index}`}
                        style={{ width: "100%", height: "243px" }}
                      />
                      <div className="d-flex flex-column justify-content-between align-items-center text-wrap my-2">
                        <h2 className="text-light">{slide.title}</h2>
                        <div className="slide-content">
                          <p className="text-light text-center">
                            {slide.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </div>
      </div>
      <div className="container d-flex justify-content-center mt-2 mb-5">
        <Link className="btn btn-primary text-light" to="/products">
          READ MORE ABOUT MOLDS
        </Link>
      </div>
    </>
  );
}
