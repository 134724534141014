import React from "react";
import "../Style/Product.css";
import Vpsheader from "./Vpsheader";
import VpsProducts from "./VpsProducts";
import VpsFooter from "./VpsFooter";
import { Helmet } from "react-helmet";

export default function VpsProductsBootStrap() {
  return (
    <>
    
      <Vpsheader />
      
      <main>
      <Helmet>
          <meta charset="utf-8" />
          <title>Discover Our Mold Masterpieces</title>
          <meta
            name="description"
            content="Explore our diverse range of meticulously crafted molds, each a testament to our dedication to perfection."
          />
          <meta
            name="keywords"
            content="Mold, Mould, Injection molding, Injection moulding, Automobile mold, Toy mold"
          />
          <meta name="author" content="V.P.S Engineering Corporation" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="vpsecorp" />
          <meta
            name="twitter:title"
            content="Discover Our Mold Masterpieces"
          />
          <meta
            name="twitter:description"
            content="Explore our diverse range of meticulously crafted molds, each a testament to our dedication to perfection."
          />
          <meta name="twitter:creator" content="vpsecorp" />
          <meta
            name="twitter:image"
            content="https://www.vpsecorp.com/vpslogo.png"
          />
          <link rel="canonical" href="https://www.vpsecorp.com/" />
          <meta
            property="og:title"
            content="Discover Our Mold Masterpieces"
          />
          <meta property="og:url" content="https://www.vpsecorp.com/" />
          <meta
            property="og:image"
            content="https://www.vpsecorp.com/vpslogo.png"
          />
          <meta
            property="og:description"
            content="Explore our diverse range of meticulously crafted molds, each a testament to our dedication to perfection."
          />
          <meta property="og:site_name" content="vpsecorp" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5"
          />
          <meta http-equiv="Cache-Control" content="no-cache" />
        </Helmet>
        <div className="container my-4 d-none">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div className="col">
              <div className="card rounded shadow-lg">
              <img className="bd-placeholder-img rounded card-img-top" width="100%"
                  height="350" src={process.env.PUBLIC_URL + "/Haas VM 3.jpg"} />
                  <div className="card-body">
                  <h1 className="card-text d-flex justify-content-center">Haas</h1>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card rounded shadow-lg">
              <img className="bd-placeholder-img rounded card-img-top" width="100%"
                  height="350" src={process.env.PUBLIC_URL + "/Hantian ma1200.jpg"} />
                  <div className="card-body">
                  <h1 className="card-text d-flex justify-content-center">Hantian</h1>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card rounded shadow-lg">
                <img className="bd-placeholder-img rounded card-img-top" width="100%"
                  height="350" src={process.env.PUBLIC_URL + "/ACCUTE X CNC EDM.webp"}  />
                <div className="card-body">
                  <h1 className="card-text d-flex justify-content-center">CNC EDM</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h1 className="horizontal-line d-flex my-4">Products</h1>
        <div className="container d-flex justify-content-center my-5 moldLoader">
              <div className="spinner-grow spinner-grow-sm mx-1" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div className="spinner-grow spinner-grow-sm mx-1" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div className="spinner-grow spinner-grow-sm mx-1" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div className="spinner-grow spinner-grow-sm mx-1" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div className="spinner-grow spinner-grow-sm mx-1" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div className="spinner-grow spinner-grow-sm mx-1" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
        <div className="row m-auto container moldContent">
          <VpsProducts />
        </div>
      </main>
      <div className="container">
        <hr />
      </div>
      <VpsFooter />
    </>
  );
}
